<template>
  <div :class="$style.buttonGroups">
    <slot />
    <a
      :class="[$style.button]"
      v-if="$p.action('UPDATE')"
      @click="handleEdit"
      >{{ $t('common.edit') }}</a
    >
    <a :class="[$style.button]" v-if="showVideo" @click="handlePlay">{{
      $t('common.viewMonitoring')
    }}</a>
    <a :class="[$style.button]" @click="handleDetail"
      >{{ $t('common.detail') }}
      <span :class="$style.messageCount" v-if="num > 0">{{ num }}</span></a
    >
    <a
      :class="[$style.button, $style.red]"
      v-if="$p.action('DELETE')"
      @click="handleDelete"
      >{{ $t('common.delete') }}</a
    >
  </div>
</template>
<script>
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

@Component()
export default class ButtonGroups extends Vue {
  @Prop({ type: Number, default: 0 }) num;
  @Prop({ type: Boolean, default: false }) showVideo;
  @Emit()
  handleEdit() {}
  @Emit()
  handlePlay() {}
  @Emit()
  handleDetail() {}
  @Emit()
  handleDelete() {}
}
</script>
<style lang="less" module>
.buttonGroups {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .red {
    color: red;
  }
  & > a,
  .button {
    margin-right: 15px;
    position: relative;
    .messageCount {
      position: absolute;
      font-size: 12px;
      top: 2px;
      right: -8px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      border-radius: 50%;
      background: var(--font-active);
      color: var(--table-row);
      text-align: center;
    }
  }
}
</style>
