import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  addDevice,
  modifyDevice,
  projectList,
} from '@/services/device-manage/device';
@Component
export default class FormCommon extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) operationType;
  canEdit = true;
  mounted() {
    this.initData();
    this.getProjectList();
  }
  deviceId = '';
  initData() {
    this.deviceId = '';
    if (this.operationType === 'edit') {
      const data = this.editData;
      this.canEdit = false;
      this.deviceId = data.pkId;
      this.initFormData(data);
    }
  }
  handleProjectChange(projectId) {
    this.form.projectId = projectId;
  }
  projectList = [];
  async getProjectList() {
    this.projectList = await projectList();
  }
  valid = true;
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (!this.valid) {
          this.verify();
        }
        if (valid && this.valid) {
          const type = this.operationType === 'add';
          const params = JSON.parse(JSON.stringify(this.postData));
          delete params.deviceGroup;
          if (!type) {
            params.deviceId = this.deviceId;
          }
          try {
            const str = type
              ? this.$t('common.addSuccess')
              : this.$t('common.modifySuccess');
            this.operationType === 'add'
              ? await addDevice(params, this.postData.deviceGroup)
              : await modifyDevice(params, this.postData.deviceGroup);
            this.$message.success(str);
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
