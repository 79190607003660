import { Component, Vue } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import Tip from '@/components/tip';
import { PublicSize } from '@/views/green-construction/components/utils';
import { createDrawer, createFormModal } from '@triascloud/x-components';
import {
  deleteDeviceById,
  deviceList,
  deviceStatusCount,
  deviceInfo,
} from '@/services/device-manage/device';
@Component
export default class TableCommon extends Vue {
  mounted() {
    this.getTableList();
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  queryParams = {
    device: '',
    projectId: null,
    query: {
      current: 1,
      size: 10,
    },
  };
  tableData = [];
  loading = false;
  showStatusNum = true;
  async getTableList() {
    this.loading = true;
    if (this.showStatusNum) {
      this.getDeviceStatusCount();
    }
    try {
      const { records, current, size, total } = await deviceList(
        this.queryParams,
        this.deviceGroup,
      );
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
    } finally {
      this.loading = false;
    }
  }
  offlineNum = 0;
  onlineNum = 0;
  async getDeviceStatusCount() {
    const { offlineNum, onlineNum } = await deviceStatusCount(
      this.queryParams,
      this.deviceGroup,
    );
    this.offlineNum = offlineNum;
    this.onlineNum = onlineNum;
  }
  @Debounce(500)
  handleSearchSubmit(data) {
    this.pagination.current = 1;
    this.queryParams = Object.assign(this.queryParams, data);
    this.getTableList();
  }
  async deleteTips(record) {
    const text = this.$t('safety.sureDeleteDevice');
    const tips = this.$t('safety.deleteDeviceTips');
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span style="color: #F72F2F">{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: PublicSize.DeleteModalSize,
          title: this.$t('safety.importanceTips'),
          onOk: () => {
            this.deleteDevice(record.pkId);
          },
        },
      );
    } catch {
      return false;
    }
  }
  async deleteDevice(id) {
    try {
      await deleteDeviceById(id, this.deviceGroup);
      this.$message.success(this.$t('safety.deleteSuccess'));
      if (this.pagination.current > 1) {
        if (this.tableData.length === 1) {
          this.pagination.current--;
        }
      }
      await this.getTableList();
    } catch {
      return false;
    }
  }
  async showFormModel(row, operationType) {
    try {
      const result = await createFormModal(
        () => <FormModel editData={row} operationType={operationType} />,
        {
          width: PublicSize.FormModalSize,
          title:
            operationType === 'add'
              ? this.$t('safety.addDevice')
              : this.$t('safety.editDevice'),
        },
      );
      if (result) {
        await this.getTableList();
      }
    } catch {
      return false;
    }
  }
  modalDrawer = null;
  handleCloseModalDrawer() {
    this.modalDrawer.handleClosed();
  }
  async showDetail(record) {
    try {
      // 需要通过获取设备信息这个接口来更新设备的状态
      const data = await deviceInfo(record.pkId, this.deviceGroup);
      record.online = data.online;
      this.modalDrawer = createDrawer(
        () => (
          <device-detail data={record} close={this.handleCloseModalDrawer} />
        ),
        {
          title: this.$t('safety.deviceDetail'),
          width: PublicSize.FormDetailSize,
          wrapClassName: 'DeviceDetail',
        },
      );
    } catch {
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.queryParams.query.current = data.current;
    this.queryParams.query.size = data.pageSize;
    this.getTableList();
  }
}
