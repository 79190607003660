<template>
  <a-form-model :class="$style.search" layout="inline" :model="form">
    <div :class="$style.itemWrap">
      <div :class="$style.item">
        <a-form-model-item :label="$t('safety.device')">
          <a-input
            v-model="form.device"
            allow-clear
            @change="() => handleSearchSubmit()"
            :placeholder="$t('safety.searchNameCode')"
          />
        </a-form-model-item>
      </div>
      <div :class="$style.item">
        <a-form-model-item :label="$t('safety.projectName')">
          <a-select
            v-model="form.projectId"
            :placeholder="$t('safety.chooseProject')"
            style="width: 200px;"
            allowClear
            @change="() => handleSearchSubmit()"
          >
            <a-select-option
              v-for="item in projectList"
              :key="item.pkId"
              :value="item.pkId"
            >
              {{ item.projectName }}
            </a-select-option>

            <template slot="notFoundContent">
              <a-empty :image="simpleImage" />
            </template>
          </a-select>
        </a-form-model-item>
      </div>
      <slot />
      <div :class="$style.item" v-if="showStatusNum">
        <online-offline :online="onlineNum" :offline="offlineNum" />
      </div>
    </div>
    <div :class="$style.btn">
      <a-button
        type="primary"
        v-if="$p.action('CREATE')"
        @click="handleAddDevice"
        >{{ $t('safety.addDevice') }}</a-button
      >
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { Empty as EmptyObj } from 'ant-design-vue';
import { projectList } from '@/services/device-manage/device';
import OnlineOffline from '@/views/green-construction/components/online-offline.vue';

@Component({
  components: {
    OnlineOffline,
  },
})
export default class TableSearch extends Vue {
  @Prop({ type: Boolean, default: true }) showStatusNum;
  @Prop({ type: [Number, String], default: '0' }) onlineNum;
  @Prop({ type: [Number, String], default: '0' }) offlineNum;
  @Prop({
    type: Object,
    default: () => ({
      device: '',
      projectId: undefined,
    }),
  })
  form;
  projectList = [];
  async getProjectList() {
    this.projectList = await projectList();
  }
  created() {
    this.getProjectList();
  }
  simpleImage;
  beforeCreate() {
    this.simpleImage = EmptyObj.PRESENTED_IMAGE_SIMPLE;
  }
  @Emit()
  handleSearchSubmit() {
    return this.form;
  }
  @Emit()
  handleAddDevice() {}
}
</script>
<style lang="less" module>
.search {
  display: flex;
  justify-content: space-between;
  .itemWrap {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    display: inline-block;
  }
  .btn {
    > button {
      margin: 0 0 10px 10px;
    }
  }
  :global .ant-form-item {
    margin-bottom: 15px;
    .ant-form-item-label > label:after {
      content: ':';
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
}
</style>
